export const abbreviateNumber = (input: string, skip?: boolean): string => {
  if (skip) return input
  const strWithoutSpaces = input.replace(/\s/g, '')
  const matches = strWithoutSpaces.match(/\d+/)
  const number = matches ? +matches[0] : 0

  if (number < 1000 || skip) return input

  const roundedNumber = Intl.NumberFormat('en-US', {
    maximumFractionDigits: 0,
    notation: 'compact',
  }).format(number)

  return `${roundedNumber} +`
}
