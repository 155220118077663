import type { FC } from 'react'
import styled from 'styled-components'

import { BlocksKeys } from '@redux/features/homePage/constants/blockHomePage.constants'

import type { AboutType } from '@Types/blocks/about'
import type { ExtendBlocksType } from '@Types/blocks/blocks'
import Item from '@components/mainPageBlocks/About/components/Item'
import Text from '@typography/Text'

type Props = {
  data: ExtendBlocksType
}

const About: FC<Props> = ({ data }) => {
  const { body } = data as AboutType
  const { about_item } = data as AboutType

  return (
    <Container data-block={BlocksKeys.ABOUT}>
      <Text size="l">
        <Description dangerouslySetInnerHTML={{ __html: body.value }} />
      </Text>
      <Items>
        {about_item.map(itemId => (
          <Item itemId={itemId} key={itemId} />
        ))}
      </Items>
    </Container>
  )
}

const Container = styled.section`
  width: 100%;
  min-height: 309px;
  border: 1px solid ${p => p.theme.colors.backgroundBorder};
  border-radius: ${p => p.theme.borderRadius.default};
  background: ${p => p.theme.colors.backgroundBlock};
  padding-block: 48px;
  padding-inline: 64px 104px;
  display: grid;
  align-items: center;
  grid-template-columns: 2fr 1fr;
  grid-gap: 25px;

  img {
    filter: ${p => p.theme.colors.backgroundImageYellow};
  }

  ${p => p.theme.mediaQueries.desktop} {
    padding: 30px;
    min-height: 273px;
  }

  ${p => p.theme.mediaQueries.mobileTablet} {
    min-height: auto;
    grid-template-columns: 100%;
    width: 100%;
    padding: 0;
    border-radius: 0;
    justify-content: center;
    border: none;
  }

  ${p => p.theme.mediaQueries.mobile} {
    padding-inline: ${p => p.theme.padding.mobileBlock}px;
  }
`

const Description = styled.div`
  max-width: 499px;

  ins {
    text-underline-offset: 5px;
  }

  ${p => p.theme.mediaQueries.mobileTablet} {
    max-width: 100%;
  }
`

const Items = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 35px 15px;
  min-width: min-content;
  align-items: center;

  ${p => p.theme.mediaQueries.mobileTablet} {
    grid-template-columns: repeat(4, 1fr);
    min-height: auto;
  }
`

export default About
